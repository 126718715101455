<template>
  <div class="banner">
    <fa
      :icon="['fas', 'leaf-maple']"
      class="icon"
    />
    <div class="content">
      <div class="highlight">
        {{ t('highlight') }}
      </div>
      <div>{{ t('description') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
</script>

<style scoped lang="scss">
.banner {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  padding: 0.5rem 0.875rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  background-color: getColor('highlight-500');
  color: #fff;

  @include caption;

  @include media-min-size(large) {
    white-space: nowrap;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  @include media-min-size(large) {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #fc9065;
}

.highlight {
  @include strong-1;
}
</style>

<i18n lang="json">
{
  "en": {
    "highlight": "Fall getaways!",
    "description": "Cozy up this Thanksgiving weekend!"
  },
  "fr": {
    "highlight": "Escapade automnale !",
    "description": "Passez cette action de grâce à l’aise !"
  }
}
</i18n>
