<template>
  <div :class="['search-form', { active: hasActiveParameters }]">
    <SearchLocationToggle
      :location="locationModel"
      :label-text="locationLabelText"
      :with-search-button="showSearchButton"
      :prevent-overlay-control-scroll="preventOverlayControlScroll"
      class="location"
      @update="($event) => updateLocation($event)"
      @apply="(location) => applyLocation(location)"
      @clear="() => clearLocation()"
    />

    <SearchDatesToggle
      :dates="datesModel"
      :show-form-buttons="!showSearchButton"
      :open-calendar="showForm === 'dates'"
      :prevent-overlay-control-scroll="preventOverlayControlScroll"
      class="dates"
      @update:dates="($event) => updateDates($event)"
      @apply="({ dates }) => applyDates({ dates })"
      @clear="() => clearDates()"
    />

    <SearchGuestsToggle
      :guests="guestsModel"
      :pet-friendly="petFriendlyModel"
      :show-form-buttons="!showSearchButton"
      :open-guest-picker="showForm === 'guests'"
      :prevent-overlay-control-scroll="preventOverlayControlScroll"
      class="guests"
      @update="($event) => updateGuests($event)"
      @apply="($event) => applyGuests($event)"
      @clear="() => clearGuests()"
    />

    <div
      v-if="showSearchButton"
      class="submit"
    >
      <ZButton
        data-testid="search-button"
        block
        @click="submit()"
      >
        {{ $t('actions.search') }}
      </ZButton>
    </div>
  </div>
</template>

<script>
import { SearchTypes, QueryStrings, RVTypeEnumKey } from '~/lib/enums'
import { towable as trailer, drivable as motorized } from '~/assets/data/rvtypes'
import { ROUTE_BASE_NAME } from '~/constants'

export default {
  name: 'SearchForm',

  props: {
    showSearchButton: {
      type: Boolean,
      default: false,
    },

    searchType: {
      type: String,
      default: null,
    },

    searchOnChange: {
      type: Boolean,
      default: false,
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit'],

  setup() {
    const { t } = useI18n()

    const { isRvSearchPage } = useBaseName()

    return { t, isRvSearchPage }
  },

  data() {
    return {
      locationModel: this.$search.parameters?.location,
      datesModel: this.$search.parameters?.dates?.dates,
      guestsModel: this.$search.parameters?.guests,
      petFriendlyModel: this.$search.parameters?.filters?.petFriendly,
      showForm: null,
    }
  },

  computed: {
    isSearching() {
      return this.isRvSearchPage
    },

    hasActiveDatesParameters() {
      return this.datesModel.start && this.datesModel.end
    },

    hasActiveGuestsParameters() {
      if (this.petFriendlyModel) {
        return true
      }
      return this.guestsModel.adults + this.guestsModel.children > 0
    },

    hasActiveParameters() {
      if (this.$search.parameters.location?.fullName || this.$search.parameters.bounds?.hasBounds) {
        return true
      }

      if (this.hasActiveDatesParameters) {
        return true
      }

      if (this.hasActiveGuestsParameters) {
        return true
      }

      return false
    },

    locationLabelText() {
      switch (this.searchType) {
        case SearchTypes.Drivable:
        case SearchTypes.Towable:
          return this.t('pickupLocation')

        case SearchTypes.Delivery:
          return this.t('deliveryLocation')

        default:
          return this.t('location')
      }
    },

    canUpdateSearchParameters() {
      return this.searchOnChange || (!this.showSearchButton && this.isSearching)
    },
  },

  watch: {
    '$search.parameters.location': {
      handler: function (newVal) {
        this.locationModel = newVal
      },

      deep: true,
    },

    '$search.parameters.dates': {
      handler: function ({ dates }) {
        this.datesModel = dates
      },

      deep: true,
    },

    '$search.parameters.guests': {
      handler: function (newVal) {
        this.guestsModel = newVal
      },

      deep: true,
    },

    '$search.parameters.filters.petFriendly': {
      handler: function (newVal) {
        this.petFriendlyModel = newVal
      },
    },
  },

  methods: {
    async updateRoute() {
      if (this.searchOnChange && !this.isSearching) {
        await this.$search.updateRoute(this.localePath('rv-search'))
        return
      }
    },

    updateLocation(location) {
      if (this.showSearchButton) {
        this.locationModel = this.$search.parseGooglePlace(location)

        // Open next field if not filled
        if (!this.hasActiveDatesParameters) {
          this.showForm = 'dates'
        }
        else if (!this.hasActiveGuestsParameters) {
          this.showForm = 'guests'
        }
      }
    },

    async applyLocation(location) {
      this.locationModel = this.$search.parseGooglePlace(location)

      if (this.canUpdateSearchParameters) {
        await this.$search.updateParameters({ location: this.locationModel })
      }

      await this.updateRoute()

      // Open next field if not filled
      if (!this.hasActiveDatesParameters) {
        this.showForm = 'dates'
      }
      else if (!this.hasActiveGuestsParameters) {
        this.showForm = 'guests'
      }
    },

    clearLocation() {
      this.locationModel = {}

      if (this.canUpdateSearchParameters) {
        this.$search.clearLocation()
      }
    },

    updateDates(dates) {
      if (this.showSearchButton) {
        this.datesModel = dates

        // Open next field if not filled
        if (this.datesModel.start && this.datesModel.end && !this.hasActiveGuestsParameters) {
          this.showForm = 'guests'
        }
      }
    },

    async applyDates({ dates }) {
      this.datesModel = dates

      if (this.canUpdateSearchParameters) {
        this.$search.updateDates(dates)
      }

      await this.updateRoute()

      // Open next field if not filled
      if (!this.hasActiveGuestsParameters) {
        this.showForm = 'guests'
      }
    },

    resetDatesModel() {
      this.datesModel = {
        start: null,
        end: null,
      }
    },

    clearDates() {
      this.resetDatesModel()

      if (this.canUpdateSearchParameters) {
        this.$search.updateDates({})
      }
    },

    updateGuests({ guests, petFriendly }) {
      if (this.showSearchButton) {
        this.guestsModel = guests
        this.petFriendlyModel = petFriendly
      }
    },

    async applyGuests({ guests, petFriendly }) {
      this.guestsModel = guests
      this.petFriendlyModel = petFriendly

      if (this.canUpdateSearchParameters) {
        this.$search.updateGuests({ ...guests, petFriendly })
      }

      await this.updateRoute()
    },

    clearGuests() {
      this.guestsModel = {
        adults: null,
        children: null,
        pets: false,
      }

      this.petFriendlyModel = false

      if (this.canUpdateSearchParameters) {
        this.$search.updateGuests({})
      }
    },

    async submit() {
      if (!this.isSearching) {
        let types = []
        if (this.searchType === SearchTypes.Drivable) {
          types = [
            RVTypeEnumKey.ClassA,
            RVTypeEnumKey.ClassB,
            RVTypeEnumKey.ClassC,
            RVTypeEnumKey.Campervan,
            RVTypeEnumKey.TruckCamper,
          ]
        }
        else if (this.searchType === SearchTypes.Towable) {
          types = [
            RVTypeEnumKey.FifthWheel,
            RVTypeEnumKey.Hybrid,
            RVTypeEnumKey.MicroTrailer,
            'SubTravelTrailer',
            RVTypeEnumKey.TentTrailer,
            RVTypeEnumKey.ToyHauler,
          ]
        }

        const newQuery = {
          [QueryStrings.searchAddress]: this.locationModel?.fullName || undefined,
          [QueryStrings.startDate]: this.datesModel?.start || undefined,
          [QueryStrings.endDate]: this.datesModel?.end || undefined,
          [QueryStrings.adults]: this.guestsModel?.adults || undefined,
          [QueryStrings.children]: this.guestsModel?.children || undefined,
          [QueryStrings.types]: types?.join(',') || undefined,
        }

        if (this.petFriendlyModel) {
          newQuery[QueryStrings.petFriendly] = null
        }

        if (this.searchType === SearchTypes.Delivery) {
          newQuery[QueryStrings.delivery] = null
        }

        await this.$search.updateParameters({
          location: this.locationModel,
          dates: this.datesModel,
          guests: { adults: this.guestsModel.adults, children: this.guestsModel.children, petFriendly: this.petFriendlyModel },
        })

        this.$emit('submit')

        await navigateTo(
          {
            path: this.localePath(ROUTE_BASE_NAME.rv_search),
            query: newQuery,
          },
        )

        return
      }

      let filters = null

      if (this.searchType) {
        let drivable = undefined
        let towable = undefined

        if (this.searchType === SearchTypes.Drivable) {
          drivable = {}
          motorized.forEach((rvType) => {
            drivable[rvType] = true
          })
        }
        if (this.searchType === SearchTypes.Towable) {
          towable = {}
          trailer.forEach((rvType) => {
            towable[rvType] = true
          })
        }

        filters = {
          delivery: this.searchType === SearchTypes.Delivery,
          drivable,
          towable,
        }
      }

      await this.$search.updateParameters({
        location: this.locationModel,
        dates: { ...this.datesModel },
        guests: { ...this.guestsModel, petFriendly: this.petFriendlyModel },
        filters,
      })

      this.$emit('submit')
    },
  },
}
</script>

<i18n lang="json">
{
  "en": {
    "location": "Location",
    "pickupLocation": "Pickup Location",
    "deliveryLocation": "Delivery location"
  },
  "fr": {
    "location": "Endroit",
    "pickupLocation": "Lieu de prise en charge",
    "deliveryLocation": "Lieu de livraison"
  }
}
</i18n>
